<template>
  <div
    ref="popoverRef"
    class="popover absolute grid animate-fade cursor-default gap-4 rounded-lg bg-white text-sm duration-300 animate-duration-300 animate-ease-in-out"
    :class="[modelValue ? '' : 'hidden', popoverStyles.base]"
  >
    <slot />

    <div
      class="popover__arrow absolute size-0 bg-inherit after:block after:size-4 after:rotate-45 after:bg-inherit"
      :class="popoverStyles.arrow"
    />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    activator: Element | undefined;
    top?: boolean;
    bottom?: boolean;
    left?: boolean;
    right?: boolean;
    persistent?: boolean;
  }>(),
  {
    top: false,
    bottom: false,
    left: false,
    right: false,
    persistent: false,
  },
);

const modelValue = defineModel<boolean>({
  default: false,
});

const emit = defineEmits(['close']);

const popoverRef = ref<HTMLDivElement | null>(null);

useResizeObserver(
  popoverRef,
  () => {
    requestAnimationFrame(() => {
      const popoverOffset = 8;

      const { left: baseLeftPosition, width: baseWidth } =
        popoverRef.value!.getBoundingClientRect();

      const { left: activatorLeftPosition, width: activatorWidth } =
        useElementBounding(props.activator! as HTMLElement);

      const arrowPosition = Math.min(
        baseWidth - 20,
        activatorLeftPosition.value -
          popoverOffset -
          baseLeftPosition +
          activatorWidth.value / 2,
      );

      popoverRef.value?.style.setProperty(
        '--popover-arrow-left',
        `${Math.round(arrowPosition)}px`,
      );
    });
  },
  { box: 'border-box' },
);

onClickOutside(popoverRef, (event: Event) => {
  const isInModal = (event.target as HTMLElement).matches('c-modal, c-modal *');

  if (
    props.persistent ||
    isInModal ||
    (event.target as HTMLElement).tagName === 'C-MENU-ITEMS' ||
    !modelValue.value
  )
    return;

  event.stopPropagation();

  emit('close');
});

const onKeyup = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    emit('close');
  }
};

const popoverStyles = computed(() => {
  const options = {
    ...props,
  };

  return Object.entries(options)
    .filter(([prop, value]) => prop !== 'modelValue' && !!value)
    .reduce(
      (styleMap, [key]) => {
        styleMap.arrow += `${styleMap.arrow.length ? ' ' : ''}popover__arrow--${key}`;
        styleMap.base += `${styleMap.base.length ? ' ' : ''}popover__base--${key}`;

        return styleMap;
      },
      { arrow: '', base: '' },
    );
});

watch(
  modelValue,
  (value) => {
    if (value) {
      // popoverRef.value?.show();
      window.addEventListener('keyup', onKeyup);

      return;
    }

    // popoverRef.value?.close();
    window.removeEventListener('keyup', onKeyup);
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.popover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  &__base {
    &--top {
      // @apply bottom-10;
    }

    &--bottom {
      // @apply top-10;
    }

    &--left {
      @apply -right-2;
    }

    &--right {
      @apply -left-2;
    }
  }

  &__arrow {
    left: var(--popover-arrow-left, 0);

    &--top {
      @apply bottom-3;
    }

    &--bottom {
      @apply -top-1;
    }
  }
}
</style>
