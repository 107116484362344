<template>
  <div>
    <transition mode="out-in" name="fab">
      <button
        v-show="isButtonVisible"
        class="fixed bottom-6 right-6 z-10 size-14 rounded-full bg-warning drop-shadow-fab"
        type="button"
        title="Open pending actions"
        @click="onDialogOpen()"
      >
        <span class="relative flex size-full items-center justify-center">
          <span
            class="absolute right-0 top-0 z-10 grid h-[18px] min-w-[18px] place-content-center rounded-full border-2 border-warning bg-white p-0.5 text-xs leading-none text-warning"
          >
            {{ amountOfActions }}
          </span>

          <span
            class="absolute inline-flex size-10 animate-[ping_3s_ease-in-out_infinite] rounded-full bg-warning opacity-75"
          />

          <c-icon
            color="white"
            :path="mdiBellAlert"
            class="z-10 animate-wiggle"
          />
        </span>
      </button>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { mdiBellAlert } from '@mdi/js';
import { Dialogs } from '@/types/enum';

const { amountOfActions } = useActionStore();

const { currentDialog, addDialog } = useDialogStore();

const isModalVisible = computed(() => {
  return currentDialog.value?.component === 'LazyPendingActionsDialog';
});

const isButtonVisible = computed(() => {
  return amountOfActions.value > 0 && !isModalVisible.value;
});

const onDialogOpen = () => {
  addDialog(Dialogs.PendingActions);
};
</script>

<style lang="scss" scoped>
button {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.fab-enter-active,
.fab-leave-active {
  transition: transform 0.3s ease-in-out;
}

.fab-enter-from,
.fab-leave-to {
  transform: scale(0) rotate(180deg);
}

@keyframes showError {
  0% {
    opacity: 0;
    transform: translateY(-4px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
