<template>
  <c-side-navigation
    :mobile="isMobile"
    :style="!isMobile && 'min-width: 340px;'"
  >
    <div ref="mobileButtons" class="absolute -top-11 flex gap-2" />

    <template v-for="(item, index) in items" :key="item.path">
      <c-side-navigation-item
        v-if="!!item.children?.length"
        :active="
          item.children.some((child) => currentRoutePath.startsWith(child.path))
        "
        :data-test="`navigation-${String(item.name)}`"
        :class="{ 'mb-1': index !== items.length - 1 }"
        class="mr-6 xl:mr-0 mb-1 xl:rounded-e-none rounded-e"
      >
        <c-icon :path="item.meta.icon || ''" />
        {{ item.meta.label }}

        <div slot="sub-item">
          <nuxt-link
            v-for="child in item.children"
            :key="child.name"
            v-slot="{ navigate }"
            :to="child.path"
            custom
          >
            <c-sub-navigation-item
              :active="currentRoutePath.startsWith(child.path)"
              :data-test="`navigation-${String(child.name)}`"
              @keyup.enter="navigate"
              @click="navigate"
            >
              {{ child.meta?.label }}
            </c-sub-navigation-item>
          </nuxt-link>
        </div>
      </c-side-navigation-item>

      <nuxt-link
        v-else
        v-slot="{ navigate, isExactActive }"
        :to="item.path"
        custom
      >
        <c-side-navigation-item
          :key="`navigation-${String(item.name)}-${currentRoutePath.replaceAll('/', '-')}`"
          class="mr-6 xl:mr-0 mb-1 xl:rounded-e-none rounded-e"
          :active="isExactActive"
          :data-test="`navigation-${String(item.name)}`"
          @keyup.enter="navigate"
          @click="navigate"
        >
          <c-icon :path="item.meta.icon || ''" />
          {{ item.meta.label }}
        </c-side-navigation-item>
      </nuxt-link>
    </template>

    <c-button
      slot="bottom"
      class="mr-6"
      ghost
      inverted
      @keyup.enter="logout()"
      @click="logout()"
    >
      <c-icon :path="mdiLogout" />
      Logout
    </c-button>
  </c-side-navigation>
</template>

<script lang="ts" setup>
import { mdiLogout } from '@mdi/js';
import type { RouteRecordNormalized } from 'vue-router';

const { hasRequiredRoles, hasAnyRequiredRole, logout } = useProfileStore();

const { width } = useWindowSize();

const isMobile = computed(() => width.value < 1280);

const router = useRouter();

const currentRoutePath = computed(() => router.currentRoute.value.fullPath);

const items = computed(() => {
  return router
    .getRoutes()
    .filter((item) => {
      if (
        item.meta?.requiredRoles &&
        !hasRequiredRoles(item.meta.requiredRoles)
      ) {
        return false;
      }

      if (
        item.meta?.anyRequiredRoles &&
        !hasAnyRequiredRole(item.meta.anyRequiredRoles)
      ) {
        return false;
      }

      return item?.meta?.includeInNavigation;
    })
    .reduce((items, item) => {
      if (item.meta.sublinks) {
        item.meta.sublinks.forEach((sub) => {
          item.children = item.children || [];

          const existingChild = item.children.find(
            (child) => child.path === `${item.path}?tab=${sub.tab}`,
          );

          if (!existingChild) {
            item.children.push({
              ...item,
              children: [],
              path: `${item.path}?tab=${sub.tab}`,
              name: `${item.name as string}${toTitleCase(sub.tab)}`,
              meta: {
                ...item.meta,
                label: sub.label,
              },
            });
          }
        });
      }

      items.push(item);

      return items;
    }, [] as RouteRecordNormalized[])
    .sort(
      (a, b) =>
        (a.meta?.navigationOrder || 999) - (b.meta?.navigationOrder || 999),
    );
});
</script>

<style></style>
