<template>
  <c-card ref="mailboxElement">
    <div class="flex items-start justify-between pr-4">
      <c-card-title>Inbox</c-card-title>

      <c-tag v-if="unread" :badge="unread" class="-mt-0.5" active flat>
        unread
      </c-tag>
    </div>

    <c-loader v-if="isLoading('getMailboxMessages')" />

    <c-card-content v-if="!messages?.length">No messages</c-card-content>

    <template v-else>
      <scroll-shadow
        :style="limitHeightStyles"
        :class="{ '!overflow-hidden': !!selectedMessage?.id }"
      >
        <div ref="contentRef">
          <ul ref="messageList" class="flex flex-col">
            <template v-for="(message, index) in messages" :key="message.id">
              <li
                v-if="index === 0 || messages[index - 1].day !== message.day"
                class="text-system px-6 py-2 text-sm"
              >
                {{ message.day !== today ? message.day : 'Today' }}
              </li>

              <li
                ref="messageListItems"
                class="group relative grid cursor-pointer grid-cols-[auto_1fr] gap-3 px-6 py-2 text-sm hover:bg-primary-100"
                tabindex="0"
                @click="onMessageClick(message)"
                @keyup.space="onMessageClick(message)"
                @keydown.space.prevent
              >
                <div
                  :ref="(el) => activatorRefs.set(message.id, el as Element)"
                  class="grid size-7 place-items-center rounded-full"
                  :class="{
                    'before:top-4.5 bg-primary-100 text-primary before:absolute before:left-1.5 before:block before:size-2 before:rounded-full before:bg-primary-600':
                      message.status === 'UNREAD',
                    'group-hover:before:top-4.5 bg-tertiary-100 text-tertiary-600 group-hover:before:absolute group-hover:before:left-1.5 group-hover:before:block group-hover:before:size-2 group-hover:before:rounded-full group-hover:before:bg-link-200':
                      message.status !== 'UNREAD',
                  }"
                >
                  <c-icon
                    :path="
                      message.status === 'UNREAD'
                        ? mdiEmailOutline
                        : mdiEmailOpenOutline
                    "
                    :size="20"
                  />
                </div>

                <div class="grid gap-1">
                  <div
                    class="text-system flex flex-wrap items-center justify-between gap-2"
                  >
                    <p
                      class="font-semibold"
                      :class="
                        message.status === 'UNREAD'
                          ? 'text-primary-600'
                          : 'text-system'
                      "
                    >
                      {{ message.title }}
                    </p>

                    {{ formatDate(message.date, true).split(' at ')[1] }}
                  </div>
                </div>
              </li>
            </template>
          </ul>

          <c-card-actions
            v-if="showPagination"
            :key="paginationOptions.itemCount"
            justify="stretch"
          >
            <c-pagination
              v-model="paginationOptions"
              v-control
              :hide-details="isMobile"
              simple
              @changeValue="onPaginationChange()"
            />
          </c-card-actions>
        </div>
      </scroll-shadow>

      <popover
        v-if="selectedMessage?.id"
        ref="popoverRef"
        v-model="selectedMessage.isOpen"
        :activator="activatorRefs.get(selectedMessage.id)"
        class="internal-mailbox-popover z-10 ml-[22px] mt-1 w-[calc(100%_-_32px)] p-6 transition-none"
        :style="popoverPosition"
        v-bind="popoverProps"
        right
        @close="onPopoverClose(selectedMessage)"
      >
        <p class="text-base font-bold">
          {{ selectedMessage?.title }}
        </p>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="text-start" v-html="linkify(selectedMessage.body)" />

        <div class="flex items-center justify-between gap-2">
          <p class="mb-1 truncate text-start">
            {{ formatDate(selectedMessage.date, true) }} |
            {{ selectedMessage.sender }}
          </p>

          <c-icon-button
            size="small"
            text
            @click="onDeleteMessage(selectedMessage)"
          >
            <c-icon :path="mdiDelete" />
          </c-icon-button>
        </div>
      </popover>
    </template>
  </c-card>
</template>

<script setup lang="ts">
import type { CPaginationOptions } from '@cscfi/csc-ui';
import { mdiEmailOutline, mdiEmailOpenOutline, mdiDelete } from '@mdi/js';
import type { MailboxMessage } from '@/types/mailbox';
import { Dialogs } from '@/types/enum';
import { mailBoxMessageDeletedKey } from '@/eventKeys';
import type { Popover } from '#components';

const props = withDefaults(defineProps<{ limitHeight: boolean }>(), {
  limitHeight: false,
});

const { messages, total, unread, getMessages, markMessageAsRead } =
  useMailboxStore();

const { isLoading } = useLoadingStore();

const { addDialog } = useDialogStore();

const mailboxElement = ref<HTMLCCardElement | null>(null);

// const popoverWrapperRef = ref<HTMLDivElement | null>(null);
const popoverRef = ref<InstanceType<typeof Popover> | null>(null);

const { width } = useElementSize(mailboxElement);

const isMobile = computed(() => width.value < 460);

const selectedMessage = ref<MailboxMessage>();

const messageList = ref<HTMLUListElement | undefined>();

const activatorRefs = ref<Map<string, Element>>(new Map());

const popoverPosition = ref<{ top: number | string; bottom?: number | string }>(
  {
    top: 0,
    bottom: 'unset',
  },
);

const popoverProps = ref({ top: false, bottom: true });

const paginationOptions = ref<CPaginationOptions>({
  itemCount: 0,
  currentPage: 1,
  itemsPerPage: 5,
  startFrom: 0,
  endTo: 4,
  pageSizes: [5, 10, 15, 20],
});

const limitHeightStyles = ref({ maxHeight: 'auto' });

const contentRef = ref<HTMLDivElement | null>(null);

useResizeObserver(mailboxElement, () => {
  if (!props.limitHeight || !contentRef.value) return;

  limitHeightStyles.value = { maxHeight: '9999px' };

  requestAnimationFrame(() => {
    const bottomMargin = 8;

    const { height } = document.documentElement.getBoundingClientRect();
    const { height: widgetHeight, top } =
      mailboxElement.value!.getBoundingClientRect();
    const { height: contentHeight } = contentRef.value!.getBoundingClientRect();

    const isFitting = top + widgetHeight + bottomMargin <= height;

    if (!isFitting) {
      const widgetTopContentHeight = widgetHeight - contentHeight;

      const bottomOverflow =
        (height - contentHeight - top - widgetTopContentHeight) * -1;

      const newHeight = contentHeight - bottomOverflow - bottomMargin;

      limitHeightStyles.value = { maxHeight: `${newHeight}px` };
    }
  });
});

const today = formatDate(new Date().toString());

let initialized = false;

const showPagination = computed(() => {
  return total.value > Math.min(...paginationOptions.value.pageSizes!);
});

const linkify = (text: string) => {
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

  return text
    .replaceAll(': \nhttp', ': http')
    .replaceAll('\n', '<br />')
    .replace(urlRegex, (url: string) => {
      return `
        <c-link
          target="_blank"
          class="ml-1 break-all max-w-full popover-link"
          href="${url}"
          title="${url}"
          underline
        >
          ${url}
        </c-link>
      `.trim();
    });
};

const onMessageClick = (message: MailboxMessage) => {
  const bottomSafeOffset = 32;

  selectedMessage.value = message;

  message.isOpen = !message.isOpen;

  if (message.isOpen && message.status === 'UNREAD') {
    markMessageAsRead(message.id);
  }

  requestAnimationFrame(() => {
    useResizeObserver(popoverRef.value!.$el, () => {
      if (!selectedMessage.value?.id) return;

      const activator = activatorRefs.value.get(
        selectedMessage.value!.id || '',
      );

      if (!activator) {
        popoverPosition.value = { top: '0' };

        return;
      }

      const { height: screenHeight } = document.body.getBoundingClientRect();
      const {
        bottom: activatorBottom,
        top: activatorTop,
        height: activatorHeight,
      } = activator.getBoundingClientRect();
      const { height: popoverHeight } =
        popoverRef.value!.$el.getBoundingClientRect();

      if (activatorBottom + popoverHeight + bottomSafeOffset > screenHeight) {
        popoverProps.value = {
          top: true,
          bottom: false,
        };

        const topOffset = isMobile.value ? 50 : 32;

        popoverPosition.value = {
          top: `${activatorTop - popoverHeight - activatorHeight - topOffset}px`,
          bottom: 'unset',
        };

        return;
      }

      popoverProps.value = {
        top: false,
        bottom: true,
      };

      const bottomOffset = isMobile.value ? 24 : 8;

      popoverPosition.value = {
        top: `${activatorTop - bottomOffset}px`,
        bottom: 'unset',
      };
    });
  });
};

const bus = useEventBus(mailBoxMessageDeletedKey);

bus.on(() => {
  onCloseMessage();
});

const onPaginationChange = async () => {
  if (!initialized) {
    initialized = true;

    return;
  }

  await getNextMessages();
};

const getNextMessages = async () => {
  await getMessages(
    paginationOptions.value.currentPage! - 1,
    paginationOptions.value.itemsPerPage,
  );
};

const onCloseMessage = () => {
  selectedMessage.value = undefined;
};

const onDeleteMessage = (message: MailboxMessage) => {
  addDialog(Dialogs.MailboxDelete, false, { message });
};

const onPopoverClose = (message: MailboxMessage) => {
  requestAnimationFrame(() => {
    message.isOpen = false;

    selectedMessage.value = undefined;
  });
};

watch(
  total,
  (count) => {
    if (!count) return;

    paginationOptions.value.itemCount = count;
  },
  { immediate: true },
);

onMounted(() => {
  getNextMessages();
});
</script>

<style lang="scss" scoped>
.dark-theme {
  c-card {
    --c-card-background-color: var(--c-link-900);
    --c-card-title-underline-color: var(--c-accent-600);
    --c-card-title-color: var(--c-white);
  }

  c-pagination {
    --c-text-system: var(--c-link-200);
    --c-pagination-button-text-color: var(--c-accent-600);
    --c-pagination-menu-background-color-hover: var(--c-link-700);
  }

  c-tag {
    --c-tag-background-color-active: var(--c-accent-600);
  }

  c-loader {
    --c-loader-background-color: var(--c-link-900);
    --c-loader-color: var(--c-accent-600);
    --c-loader-text-color: var(--c-link-200);
  }
}
</style>
