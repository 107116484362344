<template>
  <div class="relative">
    <c-icon-button
      ref="buttonRef"
      :badge="unread > 0 ? unread : null"
      size="small"
      text
      @keyup.enter="onInboxOpen()"
      @click="onInboxOpen()"
    >
      <c-icon :path="mdiEmailOutline" />
    </c-icon-button>

    <popover
      v-model="isPopupVisible"
      class="!fixed z-10 mt-2 w-[calc(100vw_-_16px)] max-md:left-0 max-md:mx-2 md:!absolute md:max-w-2xl"
      :activator="buttonRef!"
      bottom
      left
      dark
      @close="onInboxClose()"
    >
      <internal-mailbox limit-height />
    </popover>
  </div>
</template>

<script setup lang="ts">
import { mdiEmailOutline } from '@mdi/js';

const { unread } = useMailboxStore();

const buttonRef = ref<HTMLElement | null>(null);

const isPopupVisible = ref(false);

const onInboxOpen = () => {
  requestAnimationFrame(() => {
    isPopupVisible.value = true;
  });
};

const onInboxClose = () => {
  isPopupVisible.value = false;
};
</script>
