<template>
  <div class="relative">
    <c-icon-button
      ref="buttonRef"
      size="small"
      text
      @keyup.enter="onPopoverOpen()"
      @click="onPopoverOpen()"
    >
      <c-icon :path="mdiHelp" />
    </c-icon-button>

    <popover
      v-model="isPopoverVisible"
      class="!fixed z-10 mt-2 w-[calc(100vw_-_16px)] max-md:left-0 max-md:mx-2 md:!absolute md:max-w-2xl"
      :activator="buttonRef!"
      bottom
      left
      dark
      @close="onPopoverClose()"
    >
      <widget-help limit-height />
    </popover>
  </div>
</template>

<script setup lang="ts">
import { mdiHelp } from '@mdi/js';

const buttonRef = ref<HTMLElement | null>(null);

const isPopoverVisible = ref(false);

const onPopoverOpen = () => {
  requestAnimationFrame(() => {
    isPopoverVisible.value = true;
  });
};

const onPopoverClose = () => {
  isPopoverVisible.value = false;
};
</script>
